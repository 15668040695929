<template>
  <div class="registerContainer">
    <Navbar navTitle="DEALER LOGIN"></Navbar>
    <main>
      <h3 style="text-align: center; color: #fff">Login</h3>
      <div class="formContainer">
        <van-form @submit="onSubmit">
          <van-field
            v-model="form.email"
            center
            clearable
            :label="t('email')"
            :placeholder="t('inputEmail')"
            name="email"
            type="email"
            :rules="[{ required: tabIndex == 1 }]"
          >
          </van-field>

          <van-field
            v-model="form.password"
            center
            clearable
            type="password"
            :label="t('Password')"
            :placeholder="t('inputPassword')"
            name="password"
          >
          </van-field>
          <div style="margin: 56px 0 16px;">
            <van-button :loading="submitLoading" round block type="info">{{t('Sign In')}}</van-button>
          </div>
          <div style="display: flex;justify-content: space-between;align-items: center">
            <router-link style="font-size: 12px" to="./register">{{t('Sign Up')}}</router-link>
          </div>
        </van-form>
      </div>
    </main>
    <Footer></Footer>
  </div>
</template>

<script>
import jwt from 'jsonwebtoken';
import PhoneInput from '@/components/PhoneInput/PhoneInput'
const validateEmail = require('@/tools/validateEmail') 
import Footer from '@/components/Footer/Footer'
import Navbar from '@/components/Navbar/NavbarDealer'
export default {
  components: {
    PhoneInput,
    Footer,
    Navbar
  },
  data() {
    return {
      needTowFA: false,
      submitLoading: false,
      tabIndex: 1,
      firstLogin: false,
      codeCount: 0,
      codeLoading: false,
      imgCodeUrl: '',
      form: {
        phoneObj: {},
        phone: '',
        email: '',
        phoneCode: '',
        password: '',
        personCode: '',
        verifyCode: ''
      }
    }
  },
  methods: {
    t(params) {
      return this.$t(`login.${params}`)
    },
    onSubmit(e) {

      if (!validateEmail(this.form.email)) {
        return this.$ctToast('请输入正确的邮箱地址')
      }
      if (!this.form.password) {
        return this.$ctToast('请输入密码')
      }
      this.submitLoading = true
      this.$fetch({
        url: '/dealer/login',
        method: 'POST',
        data: {
          username: this.form.email,
          password: encryptWithPublicKey(this.form.password)
        }
      }).then(res => {
        console.log(res)
        this.$toast.success()
        const token = res.token;
        const tokenData = jwt.decode(token)
        window.localStorage.setItem('dealerToken', token)
        window.localStorage.setItem('dealerTokenData', JSON.stringify(tokenData))
        this.$router.push("./stakingCode")
        
      }).finally(() => {
        this.submitLoading = false
      })
    }
  }
}
</script>
<style>
html, body, #app {
  height: 100%
}
</style>
<style lang="less" scoped>
.registerContainer {
  min-height: 100%;
  background: #001938;
  main {
    padding: 0 10px;
  }
}
.formContainer {
  margin: 30px auto 0;
  width: 100%;
  max-width: 600px;
  padding: 30px;
  background: #fff;
  border-radius: 10px;
}
@media screen and (max-width: 500px) {
  .formContainer {
    padding: 30px 10px;
  }
}
</style>
